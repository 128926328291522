import { Api } from '@parkingboss/api';
import pkg from "../../../package.json";
import { derived } from "svelte/store";

const client = pkg.name.split('/')[1] || pkg.name;

export const api = Api({
    client
});

export const auth = derived(api.user, ($auth, set) => {

    // no auth
    if (!$auth || !$auth.expires) return set(null);

    const window = 60 * 1000;

    function authExpire($auth, ms) {
        if(new Date($auth.expires).getTime() < (new Date().getTime() + ms)) return true;
    }

    const i = setInterval(() => {
        if(authExpire($auth, window)) set(null);
    }, window);

    if(!authExpire($auth, window)) set($auth);

    return () => clearInterval(i); // clear the clock

});


export const token = derived(auth, $auth => $auth.token);

let email = null;

export function logIn(redirect) {
    location.href = `https://auth.communityboss.app/login?login_hint=${escape(encodeURIComponent(email||""))}&client_id=${client}&redirect_uri=${redirect || location.href}`;
}

export function ensureLoggedIn() {
    return api.isLoggedIn() || logIn();
}

ensureLoggedIn();

// monitor the token state and redirect if not logged in
auth.subscribe($auth => {
    if(!$auth) api.logOut(true); // clear underlying api
    if($auth && $auth.email) email = $auth.email; // update email tracking
    ensureLoggedIn();
});